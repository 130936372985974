export default function DateInput({value, setValue}){
    const handleChange = (e) => {
        setValue(e.target.value)
    }
    return (
        <div className="date-input h-100">
            <input 
                type="date"
                onChange={handleChange}
                name="date"
                placeholder="dd/mm/yyyy"
                value={value}
                className="text-center h-100 bg-input border-0"
            />
        </div>
    )
}