const pageVolUnit = 10;
const formatNumbericValue = (value) => value ? String(value).replace(/(.)(?=(\d{3})+$)/g, '$1,') : ''
const getColor = (valueToRef, refValue, ceiValue, flrValue, time ) => {
    if (valueToRef && valueToRef === refValue) return 'text-ref'
    if (valueToRef && valueToRef === ceiValue) return 'text-ceiling'
    if (valueToRef && valueToRef === flrValue) return 'text-floor'
    if (valueToRef && valueToRef > refValue) return 'text-green'
    if (valueToRef && valueToRef < refValue) return 'text-danger'
}

const getBgColor = (value, previous) => {
    if (previous && value && value !== previous) return 'bg-change'
    return ''
}

function TdBlock({ displayValue, valueToRef, row, tag, previous, time }) {
    const bgColor = getBgColor(displayValue, previous);
    const color = getColor(valueToRef, row.RefPrice, row.CeiPrice, row.FlrPrice, time);
    if (tag === 'vol') displayValue = displayValue / pageVolUnit
    if (typeof (displayValue) === 'string') return <span className={`w-100 h-100 d-block ${bgColor} ${color}`}>{displayValue}</span>
    if (displayValue === 0) return <span className={`w-100 h-100 d-block ${bgColor} ${color}`}></span>
    return (
        <span className={`w-100 h-100 d-block ${bgColor} ${color}`}>{formatNumbericValue(Math.abs(displayValue))}</span>
    )
}

function TdBuySellBlock({ price, vol, row, tag, previous }) {
    const bgColor = getBgColor(tag=== 'price' ? price : vol, previous)
    if (price === 0 && vol > 0) {
        const color = 'text-ATC';
        if (tag === 'price') return <span className={`w-100 h-100 d-block ${bgColor} ${color}`}>{row.AmPm === 'AM' ? 'ATO' : 'ATC'}</span>
        return <span className={`w-100 h-100 d-block ${bgColor} ${color}`}>{formatNumbericValue(vol / pageVolUnit)}</span>
    } else {
        const displayValue = tag === 'price' ? price : (vol / pageVolUnit);
        const color = getColor(price, row.RefPrice, row.CeiPrice, row.FlrPrice);
        if (typeof (displayValue) === 'string') return <span className={`w-100 h-100 d-block ${bgColor} ${color}`}>{displayValue}</span>
        if (displayValue === 0) return <span className={`w-100 h-100 d-block ${bgColor} ${color}`}></span>
        return (
            <span className={`w-100 h-100 d-block ${bgColor} ${color}`}>{formatNumbericValue(Math.abs(displayValue))}</span>
        )
    }
}

export default function StockData({ stockData }) {
    if (stockData) {
        stockData.sort((value1, value2) => value1.TradeTime < value2.TradeTime ? 1 : -1);
        return (
            <tbody>
                {stockData.map((row, index) => {
                    const previousRow = index < stockData.length ? stockData[index+1] : undefined;
                    return (
                        <tr className="text-center bg-transparent" key={index}>
                            <td className="text-center px-1">{row.TradeTime}</td>
                            <td className='mw-standard gray-block text-ref px-1'>{row.RefPrice}</td>
                            <td className='mw-standard gray-block text-ceiling px-1'>{row.CeiPrice}</td>
                            <td className='mw-standard gray-block text-floor px-1'>{row.FlrPrice}</td>
                            <td><TdBuySellBlock price={row.BidPrice3} vol={row.BidVol3} row={row} tag='price' previous={previousRow?.BidPrice3}/></td>
                            <td><TdBuySellBlock price={row.BidPrice3} vol={row.BidVol3} row={row} tag='vol' previous={previousRow?.BidVol3}/></td>
                            <td><TdBuySellBlock price={row.BidPrice2} vol={row.BidVol2} row={row} tag='price' previous={previousRow?.BidPrice2}/></td>
                            <td><TdBuySellBlock price={row.BidPrice2} vol={row.BidVol2} row={row} tag='vol' previous={previousRow?.BidVol2}/></td>
                            <td><TdBuySellBlock price={row.BidPrice1} vol={row.BidVol1} row={row} tag='price' previous={previousRow?.BidPrice1}/></td>
                            <td><TdBuySellBlock price={row.BidPrice1} vol={row.BidVol1} row={row} tag='vol' previous={previousRow?.BidVol1}/></td>
                            <td className="text-center gray-block"><TdBlock displayValue={row.Code} valueToRef={row.MatchedPrice} row={row} time={row.TradeTime}/></td>
                            <td className='gray-block'><TdBlock displayValue={row.MatchedPrice} valueToRef={row.MatchedPrice} row={row} previous={previousRow?.MatchedPrice} time={row.TradeTime}/></td>
                            <td className='gray-block'><TdBlock displayValue={row.MatchedVol} valueToRef={row.MatchedPrice} row={row} tag='vol' previous={previousRow?.MatchedVol} time={row.TradeTime}/></td>
                            <td className='gray-block'><TdBlock displayValue={row.MatchedTotalVol} valueToRef={row.MatchedPrice} row={row} tag='vol' previous={previousRow?.MatchedTotalVol} time={row.TradeTime}/></td>
                            <td className="gray-block"><TdBlock displayValue={row.MatchedChange} valueToRef={row.MatchedPrice} row={row} previous={previousRow?.MatchedChange} time={row.TradeTime}/></td>
                            <td><TdBuySellBlock price={row.OfferPrice1} vol={row.OfferVol1} row={row} tag='price' previous={previousRow?.OfferPrice1}/></td>
                            <td><TdBuySellBlock price={row.OfferPrice1} vol={row.OfferVol1} row={row} tag='vol' previous={previousRow?.OfferVol1}/></td>
                            <td><TdBuySellBlock price={row.OfferPrice2} vol={row.OfferVol2} row={row} tag='price' previous={previousRow?.OfferPrice2}/></td>
                            <td><TdBuySellBlock price={row.OfferPrice2} vol={row.OfferVol2} row={row} tag='vol' previous={previousRow?.OfferVol2}/></td>
                            <td><TdBuySellBlock price={row.OfferPrice3} vol={row.OfferVol3} row={row} tag='price' previous={previousRow?.OfferPrice3}/></td>
                            <td><TdBuySellBlock price={row.OfferPrice3} vol={row.OfferVol3} row={row} tag='vol' previous={previousRow?.OfferVol3}/></td>
                            <td className='mw-standard gray-block'><TdBlock displayValue={row.HigPrice} valueToRef={row.HigPrice} row={row} previous={previousRow?.HigPrice}/></td>
                            <td className='mw-standard gray-block'><TdBlock displayValue={row.LowPrice} valueToRef={row.LowPrice} row={row} previous={previousRow?.LowPrice}/></td>
                            <td className='mw-standard gray-block'><TdBlock displayValue={row.AvgPrice} valueToRef={row.AvgPrice} row={row} previous={previousRow?.AvgPrice}/></td>
                            <td className="px-1">{formatNumbericValue(row.FBuyVol / pageVolUnit)}</td>
                            <td className="px-1">{formatNumbericValue(row.FSellVol / pageVolUnit)}</td>
                        </tr>
                    )
                })}
            </tbody>
        )
    }
    return
}