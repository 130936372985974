import axios from "axios";
import { useEffect, useState } from "react";
import {Link} from 'react-router-dom';

import DateInput from "../../components/DateInput";
import TextInput from "../../components/TextInput";
import SearchIcon from "../../icons/SearchIcon";
import { getDefaultStockDate, formatAPIBoardDate } from "../util/util";
import StockData from "../util/StockData";


export default function SearchBar() {
    const defaultStockCode = 'VDS';
    const defaultStockDate = getDefaultStockDate();

    const [stockCode, setStockCode] = useState(defaultStockCode);
    const [stockDate, setStockDate] = useState(defaultStockDate);

    const [stockData, setStockData] = useState({success: false, list: []});

    const getStockData = async (stockCode, boardDate) => {
        try {
            await axios({
                'method': 'GET',
                'url': 'https://livedragon.haistock.pro/api/v1/intradaySearch',
                'params': {
                    'stockCode': stockCode,
                    'boardDate': boardDate
                },
            }).then(res => {
                setStockData(res.data)
            })
        } catch (error) {
            console.log('API error notification: ');
            setStockData(
                {
                    success: false,
                    message: error.message,
                    list: []
                }
            )
        }
    }

    useEffect(() => {
        getStockData(
            defaultStockCode,
            formatAPIBoardDate(defaultStockDate)
        )
    }, [defaultStockCode, defaultStockDate]);

    const searchStockData = () => {
        getStockData(
            stockCode,
            formatAPIBoardDate(stockDate)
        )
    };

    return (
        <div className="search-body">
            <div className="search-info d-flex flex-row" style={{ background: '#2c2c2e' }}>
                <div className="stock-code-position d-flex flex-row">
                    <label name="stockCode" className="px-4 py-2 text-white">Mã chứng khoán</label>
                    <TextInput value={stockCode} setValue={setStockCode} />
                </div>
                <div className="date-position d-flex flex-row" style={{ background: '#2c2c2e' }}>
                    <label name="stockDate" className="px-4 py-2 text-white">Ngày giao dịch</label>
                    <DateInput value={stockDate} setValue={setStockDate} />
                </div>
                <div className="search-button" onClick={searchStockData}>
                    <div className="my-auto p-1 mx-2 my-1 cursor-pointer h-100">
                        <SearchIcon />
                    </div>
                </div>
                <Link className="mx-2 my-auto text-white" to="/atc">ATC Data</Link>
            </div>
            <div className="search-result">
                <table className="table-bordered w-100 bg-black text-white" style={{ border: '#636262' }}>
                    <thead className="text-center bg-black">
                        <tr>
                            <th rowSpan="2">Thời gian</th>
                            <th rowSpan="2" className='gray-block mw-standard'>TC</th>
                            <th rowSpan="2" className='gray-block mw-standard'>Trần</th>
                            <th rowSpan="2" className='gray-block mw-standard'>Sàn</th>
                            <th colSpan="6">Dư mua </th>
                            <th colSpan="5" className='gray-block'>Khớp lệnh </th>
                            <th colSpan="6">Dư bán</th>
                            <th colSpan="3" className='gray-block'>Giá</th>
                            <th colSpan="2">ĐTNN</th>
                        </tr>
                        <tr>
                            <th>G3</th>
                            <th>KL3</th>
                            <th>G2</th>
                            <th>KL2</th>
                            <th>G1</th>
                            <th>KL1</th>
                            <th className='gray-block'>Mã</th>
                            <th className='gray-block'>Giá</th>
                            <th className='gray-block'>KL</th>
                            <th className='gray-block'>TKL</th>
                            <th className='gray-block'>+/-</th>
                            <th>G1</th>
                            <th>KL1</th>
                            <th>G2</th>
                            <th>KL2</th>
                            <th>G3</th>
                            <th> KL3</th>
                            <th className='gray-block mw-standard'>Cao</th>
                            <th className='gray-block mw-standard'>Thấp</th>
                            <th className='gray-block mw-standard'>TB</th>
                            <th>Mua</th>
                            <th>Bán</th>
                        </tr>
                    </thead>
                    <StockData stockData={stockData.list}/>
                </table>
            </div>
            <div className="footer-bar-top"></div>
            <div className="footer-bar">Đơn vị Giá: 1,000đ, KL: 10cp</div>
        </div>
    ) 
}
