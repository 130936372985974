import SearchBar from '../menu/SearchBar';
import SearchBarATC from '../menu/SearchBarATC';
import SearchBarATCFullField from '../menu/SearchBarATCFullField';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

export default function Screener() {
  return (
    <div className="screener">
      <Router>
        <Routes>
          <Route exec path="/" element={<SearchBar />}/>
          <Route exec path="/atc" element={<SearchBarATC />}/>
          <Route exec path="/atc-full" element={<SearchBarATCFullField />}/>
        </Routes>
      </Router>
    </div>
  )
}