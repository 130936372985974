import axios from "axios";
import { useEffect, useState } from "react";

import DateInput from "../../components/DateInput";
import TextInput from "../../components/TextInput";
import SearchIcon from "../../icons/SearchIcon";
import { getDefaultStockDate, formatAPIBoardDate, convertDateFormat, getNearestTime } from "../util/util";
import StockData from "../util/StockData";

const USAGE_DATA = {
    '0': 'RefPrice',
    '1': 'CeilPrice',
    '2': 'FlrPrice',
    '4': 'BidPrice3',
    '5': 'BidVol3',
    '6': 'BidPrice2',
    '7': 'BidVol2',
    '8': 'BidPrice1',
    '9': 'BidVol1',
    '10': 'Code',
    '11': 'MatchedPrice',
    '12': 'MatchedVol',
    '13': 'MatchedTotalVol',
    '14': 'MatchedChange',
    '16': 'OfferPrice1',
    '17': 'OfferVol1',
    '18': 'OfferPrice2',
    '19': 'OfferVol2',
    '20': 'OfferPrice3',
    '21': 'OfferVol3',
    '23': 'HigPrice',
    '24': 'LowPrice',
    '25': 'AvgPrice',
    '29': 'FBuyVol',
    '30': 'FSellVol'
}

function filterATCByCode(ATCData, stockCode) {
    var filteredATCData = [];
    var timeList = [];
    for (const ele of ATCData) {
        for (const rowData of ele.list) {
            if (rowData[0].includes(stockCode)) {
                console.log('row data', rowData)
            }
            if (rowData[0].includes(stockCode) && Object.keys(USAGE_DATA).includes(rowData[0].split(stockCode)[1])) {
                var value = rowData[1] !== '&nbsp;' ? 
                    (rowData[1].match(/[0-9]+(\.[0-9]+)?|[+\-*/.,]/g) ?
                    Number(rowData[1].match(/[0-9]+(\.[0-9]+)?|[+\-*/.,]/g).join('').replace(',', '')) : rowData[1] ) : 
                    '';
                if (rowData[1].includes('▼')) {
                    value = - value
                }
                if (['5', '7', '9', '12', '13', '17', '19', '21', '29', '30'].includes(rowData[0].split(stockCode)[1])) {
                    value *= 10;
                }
                filteredATCData.push({
                    'code': stockCode,
                    'field': USAGE_DATA[rowData[0].split(stockCode)[1]],
                    'value': value,
                    'color': rowData[2],
                    'time': ele.time.split(' ')[1],
                    'date': ele.time.split(' ')[0]
                })
                if (!timeList.includes(ele.time.split(' ')[1])){
                    timeList.push(ele.time.split(' ')[1])
                }
            }
        }
    }
    timeList.sort();
    var finalATCData = [];
    for (const time of timeList) {
        const ATCDataByTime = filteredATCData.filter(obj => obj['time'] === time);
        var dataObj = {'TradeTime': time};
        for (const obj of ATCDataByTime){
            dataObj[obj['field']] = obj['value']
        }
        finalATCData.push(dataObj)
    }
    return finalATCData
}

function filterAPIData(APIData, ATCData) {
    if (!APIData) return []
    if (!ATCData) return []
    var timeList = ATCData.map(data => data.TradeTime);
    timeList.sort();
    const filteredAPIData = APIData.filter(data => data.TradeTime >= timeList[0]);
    const notFilteredAPIData = APIData.filter(data => data.TradeTime < timeList[0]);
    var changedAPIData = [];
    for (var data of filteredAPIData) {
        const nearestTime = getNearestTime(data.TradeTime, timeList);
        if (!nearestTime) {
            changedAPIData.push(data)
        }
        const matchedATCData = ATCData.filter(ele => ele.TradeTime === nearestTime)[0];
        Object.values(USAGE_DATA).forEach(key => {
            if (matchedATCData[key]) data[key] = matchedATCData[key];
        });
        changedAPIData.push(data)
    }
    return [...notFilteredAPIData, ...changedAPIData]
}

export default function SearchBarATCFullField() {
    const defaultStockCode = 'VDS';
    const defaultStockDate = getDefaultStockDate();

    const [stockCode, setStockCode] = useState(defaultStockCode);
    const [stockDate, setStockDate] = useState(defaultStockDate);

    const [stockData, setStockData] = useState({success: false, list: []});
    const [ATCData, setATCData] = useState({success: false, list: []});
    const [combineData, setCombineData] = useState({success: false, list: []});

    const getStockData = async (stockCode, boardDate) => {
        axios.get(`https://livedragon.haistock.pro/api/v1/intradaySearch?stockCode=${stockCode}&boardDate=${boardDate}`)
            .then(res => {
                setStockData(res.data)})
            .catch(error => {
                console.log('API error notification: ');
                setStockData(
                    {
                        success: false,
                        message: error.message,
                        list: []
                    }
                )
            })
        axios.get(`https://livedragon.haistock.pro/api/v1/atcSearch?boardDate=${convertDateFormat(boardDate)}`)
            .then(res => {
                setATCData({
                    success: true,
                    list: filterATCByCode(res.data.data, stockCode)
                })
            })
            .catch(err => {
                console.log('API ATC error')
                setATCData(
                    {
                        success: false,
                        message: err.message,
                        list: []
                    }
                )
            })
    }

    useEffect(() => {
        getStockData(
            defaultStockCode,
            formatAPIBoardDate(defaultStockDate)
        )
    }, [defaultStockCode, defaultStockDate]);

    useEffect(() => {
        const changedAPIData = filterAPIData(stockData.list, ATCData.list);
        const combined = {
            success: true,
            list: [...changedAPIData, ...(ATCData.list ? ATCData.list : [])]
        };
        setCombineData(combined)
    }, [setCombineData, ATCData, stockData])

    const searchStockData = () => {
        getStockData(
            stockCode,
            formatAPIBoardDate(stockDate)
        )
    };

    return (
        <div className="search-body">
            <div className="search-info d-flex flex-row" style={{ background: '#2c2c2e' }}>
                <div className="stock-code-position d-flex flex-row">
                    <label name="stockCode" className="px-4 py-2 text-white">Mã chứng khoán</label>
                    <TextInput value={stockCode} setValue={setStockCode} />
                </div>
                <div className="date-position d-flex flex-row" style={{ background: '#2c2c2e' }}>
                    <label name="stockDate" className="px-4 py-2 text-white">Ngày giao dịch</label>
                    <DateInput value={stockDate} setValue={setStockDate} />
                </div>
                <div className="search-button" onClick={searchStockData}>
                    <div className="my-auto p-1 mx-2 my-1 cursor-pointer h-100">
                        <SearchIcon />
                    </div>
                </div>
            </div>
            <div className="search-result">
                <table className="table-bordered w-100 bg-black text-white" style={{ border: '#636262' }}>
                    <thead className="text-center bg-black">
                        <tr>
                            <th rowSpan="2">Thời gian</th>
                            <th rowSpan="2" className='gray-block mw-standard'>TC</th>
                            <th rowSpan="2" className='gray-block mw-standard'>Trần</th>
                            <th rowSpan="2" className='gray-block mw-standard'>Sàn</th>
                            <th colSpan="6">Dư mua </th>
                            <th colSpan="5" className='gray-block'>Khớp lệnh </th>
                            <th colSpan="6">Dư bán</th>
                            <th colSpan="3" className='gray-block'>Giá</th>
                            <th colSpan="2">ĐTNN</th>
                        </tr>
                        <tr>
                            <th>G3</th>
                            <th>KL3</th>
                            <th>G2</th>
                            <th>KL2</th>
                            <th>G1</th>
                            <th>KL1</th>
                            <th className='gray-block'>Mã</th>
                            <th className='gray-block'>Giá</th>
                            <th className='gray-block'>KL</th>
                            <th className='gray-block'>TKL</th>
                            <th className='gray-block'>+/-</th>
                            <th>G1</th>
                            <th>KL1</th>
                            <th>G2</th>
                            <th>KL2</th>
                            <th>G3</th>
                            <th> KL3</th>
                            <th className='gray-block mw-standard'>Cao</th>
                            <th className='gray-block mw-standard'>Thấp</th>
                            <th className='gray-block mw-standard'>TB</th>
                            <th>Mua</th>
                            <th>Bán</th>
                        </tr>
                    </thead>
                    <StockData stockData={combineData.list}/>
                </table>
            </div>
            <div className="footer-bar-top"></div>
            <div className="footer-bar">Đơn vị Giá: 1,000đ, KL: 10cp</div>
        </div>
    ) 
}