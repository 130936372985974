export default function TextInput({value, setValue}) {
    const handleChange = (e) => {
        setValue(e.target.value)
    }
    return (
        <input
            type="text"
            onChange={handleChange}
            name="text"
            value={value}
            className="text-center h-100 bg-input border-0"
        />
    )
}