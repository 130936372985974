const getYesterday = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday;
};
export const getDefaultStockDate = () => {
    return getYesterday().toISOString().slice(0, 10);
}

export const formatAPIBoardDate = (dateInput) => {
    // format: dd/mm/yyyy
    if (dateInput) {
        const splitDate = dateInput.split('-')
        return `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`
    }
    return
}

export function convertDateFormat(inputDateStr) {
    var components = inputDateStr.split("/");
    var outputDateStr =`${components[2]}-${components[1]}-${components[0]}`
    return outputDateStr;
}

function findNearestMinElement(arr, target) {
    var nearest = arr[0];
    for (const time of arr) {
        if (target >= time) {
            nearest = time
        } else {
            break
        }
    }
    return nearest
}

export function getNearestTime(timeString, timeList) {
    if (!timeList) return null;
    timeList.sort();
    const minTime = timeList[0];
    if (timeString < minTime) return null;
    return findNearestMinElement(timeList, timeString)
}
